import Hero from "components/home/hero";
// import HeroHorizontal from "components/home/heroHomeTest";
import Head from "next/head";

import Cta from "components/home/cta";
import Details from "components/home/details";
import HomeSectionOne, { Grid } from "components/home/homeSectionOne";
import HomeSectionTwo from "components/home/homeSectionTwo";
// import HolidayModal from "components/general/holidaymodal";
// import { useFeatureFlagEnabled } from "posthog-js/react";

const Home = () => {
  return (
    <>
      <Head>
        <title>Redact - Mass Delete Messages, Posts, Likes and More from 30+ Services </title>
        <meta charSet="utf-8" />
        <link rel="icon" href="/images/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="theme-color" content="#000000" />
        <meta name="robots" content="noarchive" />
        <meta
          name="description"
          content="The Redact Privacy App lets you easily Mass Delete your Messages, Posts, Likes and More from 30+ Services."
        />
        <meta name="keywords" content="redact mass delete privacy free" />

        <meta property="og:title" content="Mass delete your tweets posts & DMs on 30+ sites" />
        <meta
          property="og:description"
          content="Mass delete your Messages, Posts and Comments on over 30+ services."
        />

        <meta property="og:url" content="https://www.redact.dev" />
        <link rel="apple-touch-icon" href="images/logo192.png" />
      </Head>
      <div>
        {/* horizontalHero ? <HeroHorizontal /> : <Hero />*/}
        <Hero />
        <Grid />
        <HomeSectionTwo />
        <Details />
        <Cta />
        <HomeSectionOne />
      </div>
    </>
  );
};

export default Home;
